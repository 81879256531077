import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {useDocGenService} from '../../../providers/AppServiceProvider'
import {createEmployerDocTicket} from './sample-plan-docs/createSamplePolicyData'


export const DownloadCompanySamplePlan = () => {
    const store = useStore(), docGenService = useDocGenService()
    const allPlans = store.getPlans()

    const download = async () => {
        const ticket = createEmployerDocTicket(allPlans, store.getSubscriptionStartDate())
        const link = await docGenService.getPdfLink(ticket, `PeachyCompanyPlan-SampleLife.pdf`)

        window.open(link, `_CompanySamplePlanPdf`)
    }

    return (
        <a onClick={download}>sample company guide</a>
    )
}
