import {AnEvent} from '../../../event/event-kit'
import {Type} from 'class-transformer'
import {MrAccount} from './MrAccount'
import {MrSubscription} from './MrSubscription'
import {MrPolicy} from './MrPolicy'
import {MrPlan} from './MrPlan'

export class MrSubscriptionQuoteRequest extends AnEvent {

    quoteRequestId: string
    quoteRequestTimestamp: number

    description?: string

    @Type(() => MrAccount)
    public account: MrAccount

    @Type(() => MrSubscription)
    public subscription: MrSubscription

    @Type(() => MrPlan)
    public plans: MrPlan[]

    @Type(() => MrPolicy)
    public policies: MrPolicy[]

    public intercomVisitorId?: string
    constructor(from: MrSubscriptionQuoteRequest) {
        super()
        Object.assign(this, from)
    }
}
