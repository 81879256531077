export {CompanyLookupService, CharityLookupService} from './src/CompanyLookupService'
export {MedicalProcedureLookupService} from './src/medical/MedicalProcedureLookupService'
        import * as me from '@peachy/lookup-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dalph/DalphSpace/peachy-home/repos/peachy-mono-repo/comp-kit/lookup/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/lookup-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    