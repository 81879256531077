import equal from 'fast-deep-equal'
import {enumerate} from './enum-kit'
import {compareNumbers} from './math-kit'
import {isIsoDate} from './date-util-kit'
import {Optional} from './utility-types'
import {
    addDays,
    clamp,
    differenceInBusinessDays,
    differenceInMilliseconds,
    intervalToDuration,
    isAfter,
    isBefore,
    isSameDay,
    isValid,
    isWeekend,
    isWithinInterval,
    max,
    min,
    set,
    startOfDay,
    subDays
} from 'date-fns'


export const UK_TIMEZONE = 'Europe/London'


export const WEEK_DAYS = {
    Sunday: 'Sun',
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
} as const

export const CALENDAR_MONTHS = {
    January: 'Jan',
    February: 'Feb',
    March: 'Mar',
    April: 'Apr',
    May: 'May',
    June: 'Jun',
    July: 'Jul',
    August: 'Aug',
    September: 'Sep',
    October: 'Oct',
    November: 'Nov',
    December: 'Dec',
} as const

export const CalenderMonthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
] as const

export const CalendarMonthShortArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
] as const

export const CalenderMonths = enumerate(CalenderMonthArray)
export const CalenderMonthsShort = enumerate(CalendarMonthShortArray)

type Month =  '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'
type Day =  '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '25' | '26' | '27' | '28' | '29' | '30' | '31'
type Year = number
// naive implementation as it's not possible to enforce the year to be 4 digits (without hitting TS' Expression produces a union type that is too complex to represent)
// gives an idea as to the expected format though
export type IsoDatePartOnly = `${Year}-${Month}-${Day}`


export function readableIsoDatePartOnlyVerbose(yyyy_MM_dd?: IsoDatePartOnly) {
    const date = parseIsoDatePartOnlyString(yyyy_MM_dd)
    return date ? `${date.padded.day} ${CalendarMonthShortArray[date.month - 1]} ${date.padded.year}` : undefined
}

export function parseIsoDatePartOnlyString(yyyy_MM_dd?: IsoDatePartOnly) {
    const [_, yearStr, monthStr, dayStr] = yyyy_MM_dd?.match(/^(\d{4})[-_](\d{2})[-_](\d{2})$/) ?? []
    if (yearStr && monthStr && dayStr) {
        return {
            year: Number.parseInt(yearStr),
            month: Number.parseInt(monthStr),
            day: Number.parseInt(dayStr),
            padded: {
                year: yearStr,
                month: monthStr,
                day: dayStr,
            }
        }
    }
}

export function monthFromName(name: any) {
    let index = CalenderMonthArray.indexOf(name)
    if (index < 0) {
        index = CalendarMonthShortArray.indexOf(name)
    }
    if (index < 0) {
        throw `invalid month name ${name}`
    }
    return index + 1
}

export const monthName = (month = thisMonth()) => Object.keys(CalenderMonths)[month - 1]
export const monthShortName = (month = thisMonth()) => Object.values(CalenderMonthsShort)[month - 1]

export const thisYear = () => new Date().getFullYear()
export const thisMonth = () => new Date().getMonth() + 1
export const isLeapYear = (year = thisYear()) => new Date(year, 1, 29).getMonth() === 1

function withoutTime(date: Date) {
    const withoutTime = new Date(date)
    withoutTime.setHours(0, 0, 0, 0)
    return withoutTime
}


/**
 * @deprecated Don't use this, it has timezone issues
 */
export function areSameDate(aDate: Date, bDate: Date) {
    return areSameDateArray(dateArrayFrom(aDate), dateArrayFrom(bDate))
}

export function areSameDateArray(aDate: DateArray, bDate: DateArray = dateArrayFrom(new Date())) {
    return aDate && bDate && aDate.every((part, index) => part === bDate[index])
}


const MONTHS30 = [4, 6, 9, 11]
const MONTHS31 = [1, 3, 5, 7, 8, 10, 12]
export type MonthYearArray = [number, number];

//TODO refactor
export const daysInMonth = ([month = thisMonth(), year = thisYear()]: MonthYearArray) => {
    if (MONTHS31.includes(month)) {
        return 31
    } else if (MONTHS30.includes(month)) {
        return 30
    } else if (month === 2) {
        return isLeapYear(year) ? 29 : 28
    } else {
        // INVALID MONTH INDEX
        return undefined
    }
}

export function monthYearBefore([
                                    month = thisMonth(),
                                    year = thisYear(),
                                ]: MonthYearArray): MonthYearArray {
    const prevMonth = month > 1 ? month - 1 : 12
    const prevMonthYear = month > 1 ? year : year - 1
    return [prevMonth, prevMonthYear]
}

export function monthYearAfter([
                                   month = thisMonth(),
                                   year = thisYear(),
                               ]: MonthYearArray): MonthYearArray {
    const nextMonth = month < 12 ? month + 1 : 1
    const nextMonthYear = month < 12 ? year : year + 1
    return [nextMonth, nextMonthYear]
}

export function firstDayOfMonth([
                                    month = thisMonth(),
                                    year = thisYear(),
                                ]: MonthYearArray) {
    return new Date(year, month - 1, 1).getDay() + 1
}

export function isDayInPast(date: Date) {
    return compareDays(date, today()) === -1
}

export function isDayInFuture(date: Date) {
    return compareDays(date, today()) === 1
}

export function compareDays(aDate: Date, bDate: Date) {
    return compareNumbers(
        withoutTime(aDate).getTime(),
        withoutTime(bDate).getTime(),
    )
}

export function daysBetween(aDate: Date, bDate: Date) {
    return (withoutTime(bDate).getTime() - withoutTime(aDate).getTime()) / (24 * 60 * 60 * 1000)
}

export const isWithinBounds = (
    date: Date,
    lowerBound: Date,
    upperBound: Date,
) => {
    return (
        date &&
        (!lowerBound ||
            compareNumbers(date.getTime(), lowerBound.getTime()) >= 0) &&
        (!upperBound || compareNumbers(date.getTime(), upperBound.getTime()) <= 0)
    )
}

export function yearsSince(then: Date): number {
    const now = new Date()

    const currentYear = now.getFullYear()
    const thenYear = then.getFullYear()

    const rawYears = currentYear - thenYear
    if (isDayInFuture(plusYears(rawYears, then))) {
        return rawYears - 1
    } else {
        return rawYears
    }
}

export function yearsBetweenAccurateToTheMillisecond(start: Date, end: Date): number {
    return intervalToDuration({start, end}).years
}

export function yearsBetween(a: Date, b: Date): number {
    const aYear = a.getFullYear()
    const bYear = b.getFullYear()

    const rawYears = bYear - aYear

    if (compareDays(plusYears(rawYears, a), b) > 0) {
        return rawYears - 1
    } else {
        return rawYears
    }
}

export function dateFrom(dateArray: DateArray): Date {
    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
}

export function todayPlusYears(years: number): Date {
    return plusYears(years, today())
}

export function midPoint(a: Date, b: Date) {
    return plusYears(yearsBetween(a, b) / 2, a)
}

export function plusYears(years: number, date: Date): Date {
    const newDate = new Date(date)

    newDate.setFullYear(newDate.getFullYear() + years)
    return newDate
}

export function plusMonths(months: number, date: Date): Date {
    const newDate = new Date(date)
    newDate.setMonth(newDate.getMonth() + months)
    return newDate
}

export function plusDays(days: number, date: Date): Date {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + days)
    return newDate
}

export function today(): Date {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
}

export function tomorrow(): Date {
    const now = today()
    now.setDate(now.getDate() + 1)
    return now
}

// [Year, Month, Day]
// TODO make this clearer?
export type DateArray = [number, number, number];


export function dateFromIso(isoDate: string) {
    return isIsoDate(isoDate) ? new Date(isoDate) : undefined
}


export const isoDateRegex = /^(\d{4})-([01]\d)-([0-3]\d)(?:T(\d{2}):(\d{2}):(\d{2})(?:\.(\d+))?)?(Z|[+-]\d{2}:\d{2})?$/

export const timezoneOffsetRegex = /([+-])(\d{2}):(\d{2})/

export function parseIsoDateParts(isoDate: string): DateParts {
    const match = isoDate.match(isoDateRegex)
    if (!match) return null

    const [_, year, month, day, hours, minutes, seconds, fractional, timezone] = match

    let timezoneOffsetMinutes = 0

    if (timezone) {
        const timezoneOffsetParts = timezone.match(timezoneOffsetRegex)
        if (timezoneOffsetParts) {
            const [_, sign, h, m] = timezoneOffsetParts
            timezoneOffsetMinutes = (sign === '+' ? 1 : -1) * ((+h * 60) + (+m))
        }
    }

    return {
        year: +year,
        month: +month,
        day: +day,
        hours: +(hours ?? 0),
        minutes: +(minutes ?? 0),
        seconds: +(seconds ?? 0),
        fractional: +(fractional ?? 0),
        timezone: timezone ?? 'Z',
        timezoneOffsetMinutes,
    }
}


export function isIsoDateFormat(isoDate: string) {
    return !!isoDate.match(isoDateRegex)
}


export type DateParts = {
    year: number
    month: number
    day: number
    hours: number
    minutes: number
    seconds: number
    fractional: number
    timezoneOffsetMinutes: number
    timezone: string
}


export function formatIsoDateOnly(date: Date): string {
    if (date) {
        const year = `${date.getFullYear()}`
        const month = `${date.getMonth() + 1}`.padStart(2, '0')
        const day = `${date.getDate()}`.padStart(2, '0')
        return `${year}-${month}-${day}`
    } else {
        return null
    }
}


// I know. I just don't have the time to risk replacing all date handling with a consistent lib
export function formatIsoDateTimeOnly(date: Date): string {
    if (date) {
        const year = `${date.getFullYear()}`
        const month = `${date.getMonth() + 1}`.padStart(2, '0')
        const day = `${date.getDate()}`.padStart(2, '0')
        const hours = `${date.getHours()}`.padStart(2, '0')
        const minutes = `${date.getMinutes()}`.padStart(2, '0')
        const seconds = `${date.getSeconds()}`.padStart(2, '0')
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
    } else {
        return null
    }
}

const pad = (num: number) => {
    const norm = Math.floor(Math.abs(num))
    return (norm < 10 ? '0' : '') + norm
}

// jeez
export function toIsoDate(date: Date) {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-'

    return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        dif +
        pad(tzo / 60) +
        ':' +
        pad(tzo % 60)
    )
}

export function isValidDateArray(dateArray: DateArray): boolean {
    if (dateArray[0] && dateArray[1] && dateArray[2]) {
        const newDateArray = dateArrayFrom(dateFrom(dateArray))
        return equal(dateArray, newDateArray)
    }
    return false
}

export function friendlyFormat(date: Date): string {
    if (compareDays(today(), date) === 0) {
        return 'Today'
    } else if (compareDays(tomorrow(), date) === 0) {
        return 'Tomorrow'
    } else {
        return date.toLocaleDateString()
    }
}

export function dateArrayFrom(date: Date | DateArray): DateArray {
    if (date instanceof Date) {
        return date ? [date.getDate(), date.getMonth() + 1, date.getFullYear()] : null
    }

    if (date?.length) {
        return date
    } else if (date) {
        // Assumption date is any type here
    } else {
        return date
    }
}

export function maxValidDate(...dates: Optional<Date>[]): Optional<Date> {
    const candidate = max(dates.filter(isValid))
    return isValid(candidate) ? candidate : undefined
}

export function minValidDate(...dates: Optional<Date>[]): Optional<Date> {
    const candidate = min(dates.filter(isValid))
    return isValid(candidate) ? candidate : undefined
}

// does not and should not take into account time of the day
export function workingDaysBetweenExclusiveOfStartAndEndDates(start: Date, end: Date, options: PublicHolidaysOptions = {}) {
    let days = 0
    if (!isSameDay(start, end)) {
        const adjustmentForExclusiveStart = isWeekend(start) ? 0 : -1
        days = differenceInBusinessDays(end, start)
        const publicHolidaysToSubtract = publicHolidayBetweenExclusiveOfStartAndEndDates(start, end, options?.publicHolidays ?? [])
        days = Math.max(0, days + adjustmentForExclusiveStart - publicHolidaysToSubtract)
    }
    return days
}

// does not and should not take into account time of the day
export function publicHolidayBetweenExclusiveOfStartAndEndDates(start: Date, end: Date, publicHolidays: Date[]) {
    let days = 0

    if (!publicHolidays?.length) {
        const exclusiveStart = addDays(startOfDay(start), 1)
        const exclusiveEnd = subDays(startOfDay(end), 1)
        if (isAfter(exclusiveEnd, exclusiveStart)) {
            days = publicHolidays.reduce((total, publicHoliday) => {
                total += isWithinInterval(publicHoliday, {start: exclusiveStart, end: exclusiveEnd}) ? 1 : 0
                return total
            }, 0)
        }
    }
    return days
}

export type WorkingHoursTime = {
    hours: number
    minutes?: number
}

export type WorkingHours = {
    from: WorkingHoursTime,
    to: WorkingHoursTime
}

export type PublicHolidaysOptions = {
    publicHolidays?: Date[]
}

export type WorkingHoursBetweenOptions = PublicHolidaysOptions & {
    workingHours?: WorkingHours
}

export type WorkingHoursRepresentations = {
    asWorkingMillis: number
    asWorkingHours: number
    asWorkingDays: number
}

export function workingHoursBetween(firstDateTime: Date, lastDateTime: Date, options: WorkingHoursBetweenOptions): WorkingHoursRepresentations {

    if (!isValid(firstDateTime) || !isValid(lastDateTime)) {
        return undefined
    }

    let asWorkingMillis = 0
    let asWorkingHours = 0
    let asWorkingDays = 0

    const workingHoursFrom = options?.workingHours?.from ?? {hours: 9}
    const workingHoursTo = options?.workingHours?.to ?? {hours: 17}
    const publicHolidays = options?.publicHolidays ?? []

    if (isBefore(firstDateTime, lastDateTime)) {

        const workingDayStartTime = {...{hours: 0, minutes: 0, seconds: 0, milliseconds: 0}, ...workingHoursFrom}
        const workingDayEndTime = {...{hours: 0, minutes: 0, seconds: 0, milliseconds: 0}, ...workingHoursTo}
        const workingMillisPerDay = differenceInMilliseconds(set(firstDateTime, workingDayEndTime), set(firstDateTime, workingDayStartTime))

        const wholeWorkingDays = workingDaysBetweenExclusiveOfStartAndEndDates(firstDateTime, lastDateTime, {publicHolidays})

        const wholeWorkingDayMillis = wholeWorkingDays * workingMillisPerDay

        let firstAndLastDayContributionMillis = 0

        if (isWorkingDay(firstDateTime, {publicHolidays})) {
            const startOfBusinessOnFirstDay = set(firstDateTime, workingDayStartTime)
            const endOfBusinessOnFirstDay = set(firstDateTime, workingDayEndTime)
            const firstDayMinContributionDateTime = clamp(firstDateTime, {start: startOfBusinessOnFirstDay, end: endOfBusinessOnFirstDay})
            const firstDayMaxContributionDateTime = clamp(lastDateTime, {start: startOfBusinessOnFirstDay, end: endOfBusinessOnFirstDay})

            const firstDayContributionMillis = differenceInMilliseconds(firstDayMaxContributionDateTime, firstDayMinContributionDateTime)
            firstAndLastDayContributionMillis += firstDayContributionMillis
        }

        if (isWorkingDay(lastDateTime, {publicHolidays}) && !isSameDay(firstDateTime, lastDateTime)) {
            const startOfBusinessOnLastDay = set(lastDateTime, workingDayStartTime)
            const endOfBusinessOnLastDay = set(lastDateTime, workingDayEndTime)
            const lastDayMinContributionDateTime = startOfBusinessOnLastDay
            const lastDayMaxContributionDateTime = clamp(lastDateTime, {start: startOfBusinessOnLastDay, end: endOfBusinessOnLastDay})

            const lastDayContributionMillis = differenceInMilliseconds(lastDayMaxContributionDateTime, lastDayMinContributionDateTime)
            firstAndLastDayContributionMillis += lastDayContributionMillis
        }

        asWorkingMillis = wholeWorkingDayMillis + firstAndLastDayContributionMillis
        asWorkingHours = asWorkingMillis / (1000 * 60 * 60)
        asWorkingDays = asWorkingMillis / workingMillisPerDay
    }

    return {
        asWorkingMillis,
        asWorkingHours,
        asWorkingDays
    }
}

function isWorkingDay(day: Date, options: PublicHolidaysOptions = {}) {
    return !isWeekend(day) && !isPublicHoliday(day, options.publicHolidays ?? [])
}

function isPublicHoliday(givenDay: Date, publicHolidays: Date[]) {
    const isHoliday = publicHolidays.some(it => isSameDay(givenDay, it))
    return isHoliday
}


export const ENGLAND_PUBLIC_HOLIDAYS_22_TO_26 = [
    // 2022
    new Date(2022, 0, 3),   // 3 January	Monday	New Year’s Day (substitute day)
    new Date(2022, 3, 15),  // 15 April	Friday	Good Friday
    new Date(2022, 3, 18),  // 18 April	Monday	Easter Monday
    new Date(2022, 4, 2),   // 2 May	Monday	Early May bank holiday
    new Date(2022, 5, 2),   // 2 June	Thursday	Spring bank holiday
    new Date(2022, 5, 3),   // 3 June	Friday	Platinum Jubilee bank holiday
    new Date(2022, 7, 29),  // 29 August	Monday	Summer bank holiday
    new Date(2022, 8, 19),  // 19 September	Monday	Bank Holiday for the State Funeral of Queen Elizabeth II
    new Date(2022, 11, 26), // 26 December	Monday	Boxing Day
    new Date(2022, 11, 27), // 27 December	Tuesday	Christmas Day (substitute day)

    // 2023
    new Date(2023, 0, 2),  // 2 January	Monday	New Year’s Day (substitute day)
    new Date(2023, 3, 7),  // 7 April	Friday	Good Friday
    new Date(2023, 3, 10), // 10 April	Monday	Easter Monday
    new Date(2023, 4, 1),  // 1 May	Monday	Early May bank holiday
    new Date(2023, 4, 8),  // 8 May	Monday	Bank holiday for the coronation of King Charles III
    new Date(2023, 4, 29), // 29 May	Monday	Spring bank holiday
    new Date(2023, 7, 28), // 28 August	Monday	Summer bank holiday
    new Date(2023, 11, 25), // 25 December	Monday	Christmas Day
    new Date(2023, 11, 26), // 26 December	Tuesday	Boxing Day

    // 2024
    new Date(2024, 0, 1), // 1 January	Monday	New Year’s Day
    new Date(2024, 2, 29), // 29 March	Friday	Good Friday
    new Date(2024, 3, 1), // 1 April	Monday	Easter Monday
    new Date(2024, 4, 6), // 6 May	Monday	Early May bank holiday
    new Date(2024, 4, 27), // 27 May	Monday	Spring bank holiday
    new Date(2024, 7, 26), // 26 August	Monday	Summer bank holiday
    new Date(2024, 11, 25), // 25 December	Wednesday	Christmas Day
    new Date(2024, 11, 26), // 26 December	Thursday	Boxing Day

    // 2025
    new Date(2025, 0, 1), // 1 January	Wednesday	New Year’s Day
    new Date(2025, 3, 18), // 18 April	Friday	Good Friday
    new Date(2025, 3, 21), // 21 April	Monday	Easter Monday
    new Date(2025, 4, 5), // 5 May	Monday	Early May bank holiday
    new Date(2025, 4, 26), // 26 May	Monday	Spring bank holiday
    new Date(2025, 7, 25), // 25 August	Monday	Summer bank holiday
    new Date(2025, 11, 25), // 25 December	Thursday	Christmas Day
    new Date(2025, 11, 26), // 26 December	Friday	Boxing Day

    // 2026
    new Date(2026, 0, 1), // 1 January	Thursday	New Year’s Day
    new Date(2026, 3, 3), // 3 April	Friday	Good Friday
    new Date(2026, 3, 6), // 6 April	Monday	Easter Monday
    new Date(2026, 4, 4), // 4 May	Monday	Early May bank holiday
    new Date(2026, 4, 25), // 25 May	Monday	Spring bank holiday
    new Date(2026, 7, 31), // 31 August	Monday	Summer bank holiday
    new Date(2026, 11, 25), // 25 December	Friday	Christmas Day
    new Date(2026, 11, 28) // 28 December	Monday	Boxing Day (substitute day)
]
