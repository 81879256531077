import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../../event-kit'
import {CancellationEventData, LifeEventData} from '../../interfaces'
import {AccountType} from '@peachy/repo-domain'
import {CancellationType} from '../../CancellationType'

export class MemberCancellationEmailSendRequest extends AnEvent implements LifeEventData, CancellationEventData {
    constructor(from: Properties<MemberCancellationEmailSendRequest>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
    lifeId: string
    cognitoId: string
    policyId: string
    cancellationReason: string
    cancellationType: CancellationType
}
