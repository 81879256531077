import {HasLength} from './utility-types'

export const sum = <T>(array: T[], prop?: (o: T) => number) => array.reduce((acc, a) => acc + (prop ? prop(a) : +a), 0)

// TODO belong to magneticStrip?
export function minElement<E>(array: E[], metric: (e: E) => number): E {
    let min = Number.MAX_SAFE_INTEGER
    let minItem: E = undefined
    array.forEach((element) => {
        const elementValue = metric(element)
        if (elementValue < min) {
            min = elementValue
            minItem = element
        }
    })
    return minItem
}

export function maxElement<E>(array: E[], metric: (e: E) => number): E {
    let max = Number.MIN_SAFE_INTEGER
    let maxElement: E = undefined
    array.forEach((element) => {
        const elementValue = metric(element)
        if (elementValue > max) {
            max = elementValue
            maxElement = element
        }
    })
    return maxElement
}


//unused
export function lengthOrdered<T extends HasLength>(...lengths: T[]): T[] {
    return lengths.sort((a, b) => {
        return a.length - b.length
    })
}

export const first = <T>(array: T[]) => (array?.length) ? array[0] : undefined
export const last = <T>(array: T[]) => (array?.length) ? array[array.length - 1] : undefined


export function replaceHead(array: any[], newItem: any) {
    const mutation = [...array]
    mutation.splice(mutation.length - 1, 1, newItem)
    return mutation
}

export function uniqueElements<T>(array: T[]): T[] {
    return [...Array.from(new Set(array))]
}

export function joinWhereDefined(separator: string, ...items: any[]) {
    return removeUndefinedsFrom(items).join(separator)
}

export function removeUndefinedsFrom(items: any[]) {
    return items.filter(it => !!it)
}

export const unique = <T>(array: T[]): T[] => [...Array.from(new Set(array))]
export const onlyTruthy = <T>(array: T[]): T[] => array.filter(e => !!e)
export const onlyUniqueTruthy = <T>(array: T[]): T[] => unique(onlyTruthy(array))


export function shuffle<T>(array: T[]) {
    const shuffled = [...array]
    const lastIndex = shuffled.length - 1
    for (let i = lastIndex; i > 0 ; i--) {
        const j = Math.round(Math.random() * i)
        const temp = shuffled[i]
        shuffled[i] = shuffled[j]
        shuffled[j] = temp
    }
    return shuffled
}
