import {MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'
import {Draft} from '@peachy/utility-kit-pure'
import {SubscriptionService} from './SubscriptionService'
import {debounce} from '@solid-primitives/scheduled'
import {IStore} from '../../global/storage/IStore'

export class SubscriptionQuoteRepository {
    private store: IStore<Draft<MrSubscriptionQuoteResponse>>
    private subscriptionService: SubscriptionService

    constructor (store: IStore<Draft<MrSubscriptionQuoteResponse>>, subscriptionService?: SubscriptionService) {
        this.store = store
        this.subscriptionService = subscriptionService
    }

    save (subscriptionQuote: Draft<MrSubscriptionQuoteResponse>) {
        this.store.save(subscriptionQuote)

        if (this.subscriptionService && subscriptionQuote?.account?.user?.cognitoUserId) {
            this.throttledSave(subscriptionQuote as MrSubscriptionQuoteResponse)
        }
    }

    get (): Draft<MrSubscriptionQuoteResponse> {
        return this.store.get()
    }

    delete () {
        this.store.delete()
    }

    throttledSave = debounce((subRequest: MrSubscriptionQuoteResponse) => this.subscriptionService.saveSmeQuote(subRequest), 3000)
}
