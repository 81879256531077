import {mapBy} from './collection-kit'
import {KeyMapped} from './utility-types'

export function keys<O>(o: O): (keyof O)[] {
    const keys: any[] = o ? Object.keys(o) : []
    return keys
}

export function values<O>(o: O): O[keyof O][] {
    return o ? Object.values(o) : []
}

export function filterValues<T, K extends string>(keyMapped: KeyMapped<T, K>, predicate: (item: T, key: K) => boolean): KeyMapped<T, K> {
    const filtered: KeyMapped<T, K> = {}
    keys(keyMapped).forEach(k => {
        if (predicate(keyMapped[k], k)) {
            filtered[k] = keyMapped[k]
        }
    })
    return filtered
}

export function mapValues<const T, const K extends string, M>(keyMapped: KeyMapped<T, K>, mapper: (item: T, key: K) => M): KeyMapped<M, K> {
    const remapped: KeyMapped<M, K> = {}
    keys(keyMapped).forEach(k => {
        remapped[k] = mapper(keyMapped[k],  k)
    })
    return remapped
}



export function pickAndClone<T, const K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    return structuredClone(pick(obj, ...keys))
}


export function mapById<T extends {id: string}>(items: T[]): KeyMapped<T> {
    return mapBy(items, (i) => i.id)
}

export type HasId<T = string> = {
    id: T
}



export function omitAndClone<T, const K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
    return structuredClone(omit(obj, ...keys))
}


export function pick<T, const K extends keyof T>(obj: T, ...keysToPick: K[]): Pick<T, K> {
    const picked: Partial<T> = {}
    keysToPick.forEach(k => {
        picked[k] = obj[k]
    })
    return picked as Pick<T, K>
}

export function omit<T, const K extends keyof T>(obj: T, ...keysToOmit: K[]): Omit<T, K> {
    const picked: Partial<T> = {...obj}
    keysToOmit.forEach(k => {
        delete picked[k]
    })
    return picked as Omit<T, K>
}
