import {Spinner} from '@peachy/client-kit'
import {useNavigate} from '@solidjs/router'
import {createEffect, createResource, Show} from 'solid-js'
import {useIamService} from '../../providers/AppServiceProvider'
import {HomeProvider} from './HomeProvider'
import {loadActiveSubscription} from './loadActiveSubscription'
import {loadSubscriptionQuote} from './loadSubscriptionQuote'
import {DashboardHeader} from '../Header/DashboardHeader/DashboardHeader'
import {Page} from '../../Page'

export function Home(props) {
    const navigate = useNavigate(), iamService = useIamService()

    const [subscriptionQuote] = createResource(loadSubscriptionQuote)
    const [currentAccountSubscription] = createResource(loadActiveSubscription)

    const isLoading = () => subscriptionQuote.loading || currentAccountSubscription.loading

    createEffect(() => {
        // hide login from logged on users
        if (iamService.isSignedIn() && location.pathname === '/login') {
            navigate('/', {replace: true})
        }
        // route guard
        if (!iamService.isSignedIn()) {
            navigate('/quote', { replace: true })
        }

        // in progress quote
        if (!currentAccountSubscription() && subscriptionQuote()) {
            navigate('/quote/plans', { replace: true })
        }

        // cancelled subscription
        if(currentAccountSubscription()?.subscription.status === 'CANCELLED') {
            navigate('/billing', { replace: true })
        }
    })


    return (
        <>
            <Show when={!isLoading() && currentAccountSubscription()}>
                <HomeProvider accountSubscription={currentAccountSubscription()}>
                    <Page header={<DashboardHeader/>}>
                        {props.children}
                    </Page>
                </HomeProvider>
            </Show>
            <Show when={isLoading()}>
                <Spinner isShown={true}/>
            </Show>
        </>
    )
}
