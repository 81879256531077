import {Premium} from '../Premium'
import {ActiveStatus} from './statuses'
import {QuoteParams} from '@peachy/quote-pure'
import {BenefitType} from '../PlanBenefit'

export class MrLifeBenefit implements QuoteParams {

    quoteModelVersion?: string
    renewalPeriodMonths?: number
    rateSet?: 'INDIVIDUAL' | 'SME' | 'VOLUNTARY'
    groupSize?: number
    moriDate?: number
    effectiveDate?: number
    annualBilling?: boolean
    excess?: number
    benefitSpecificExcess?: boolean

    id: BenefitType
    status: ActiveStatus
    startDate?: number
    endDate?: number
    premium?: Premium
    limit?: number
}
