import {MrPolicy, MrSubscription} from '@peachy/core-domain-pure'
import {Component} from 'solid-js'
import {InfoPanelPlanRenewalDate} from '../pure/InfoPanelPlanRenewalDate'

export const YourPlanPolicyDetailsPanel: Component<{ subscription: MrSubscription, policies: MrPolicy[] }> = (props) => {
    const totalMembers = () => props.policies.flatMap(p => Object.values(p.lives))?.length

    return (
        <div>
            <h5>Policy: {props.subscription.referenceNumber()}</h5>
            <InfoPanelPlanRenewalDate startDate={new Date(props.subscription.startDate)} renewalDate={props.subscription.getAnnualRenewalDate()}/>
            <p>You currently have <span>{totalMembers()} <MemberTextDisplay noOfMembers={totalMembers()}/></span> on plan</p>
        </div>
    )
}

export const MemberTextDisplay: Component<{noOfMembers : number}> = (props) => <>member{props.noOfMembers > 1 ? 's' : ''}</>
