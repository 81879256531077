import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../../event-kit'
import {SubscriptionEventData} from '../../interfaces'
import {AccountType} from '../../../../domain/models/Account'
import {LifeTurnover} from '@peachy/alterations-pure'

export class CompanyPolicyAlterationDocSendRequest extends AnEvent implements SubscriptionEventData {
     constructor(from: Properties<CompanyPolicyAlterationDocSendRequest>) {
        super()
        Object.assign(this, from)
    }
    lifeTurnover?: LifeTurnover

    accountId: string
    accountType: AccountType
    subscriptionId: string
}
