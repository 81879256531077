import {validateUser} from '../../../service/validation/ValidationService'
import {TextBox} from '../../../global/forms/TextBox/TextBox'
import {CaptureForm} from '../CaptureForm'
import {Toggle} from '../../../global/forms/Toggle/Toggle'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {createSignal} from 'solid-js'
import {User, ValidationError, ValidationErrorMap} from '@peachy/core-domain-pure'
import {useAppContext} from '../../../providers/AppContextProvider'
import {InvitePortalUserResponse} from '@peachy/iam-sme-pure'
import {useIamService, useSmeUserService} from '../../../providers/AppServiceProvider'
import {LEGAL_URL, POLICY_URL} from '../../../App'

export function CaptureAdminEmail() {

    const store = useStore()
    const appContext = useAppContext()
    const smeUserService = useSmeUserService()
    const iamService = useIamService()

    const [email, setEmail] = createSignal(store.getUser()?.email ?? '')
    const [marketingAccepted, setMarketingAccepted] = createSignal(store.getUser()?.marketingAccepted ?? false)
    const [policiesAccepted, setPoliciesAccepted] = createSignal(store.getUser()?.policiesAccepted ?? false)
    const [emailValidationError, setEmailValidationError] = createSignal()

    const isDisabled = iamService.isSignedIn()

    const handleEmailChange = () => {
        setEmailValidationError()
    }

    const handleEmailBlur = (event: any) => {
        setEmail(event.target.value)
    }

    const onSubmit = async (event: any) => {
        event.preventDefault()

        //ensure email is valid - just a thorough check to ensure we have an email address before proceeding
        if (validateFields().email) {
            return
        }

        const proceed = () => {
            store.updateEmail(email())
            store.updateMarketingAccepted(marketingAccepted())
            store.updatePoliciesAccepted(policiesAccepted())
        }

        const hasEmailChanged = !(isDisabled) && store.getUser().email !== email()

        if (!hasEmailChanged) {
            proceed()
            return
        }

        try {
            appContext.setLoading(true)

            const response = await smeUserService.invite({
                email: email(),
                firstName: store.getUser()?.firstname ?? '',
                lastName: store.getUser()?.lastname ?? ''
            }) as InvitePortalUserResponse
            store.updateUserCognitoId(response.id)

            proceed()
        }
        catch (e: any) {
            setEmailValidationError((e.response.status === 409) ? 'Account already exists with the provided email' : 'Something went wrong')
            throw Error(e)
        }
        finally {
            appContext.setLoading(false)
        }
    }

    const validateFields = (): ValidationErrorMap<User> => {
        const errors = validateUser({
            email: email(),
            marketingAccepted: marketingAccepted(),
            policiesAccepted: policiesAccepted(),
        } as User)

        if(emailValidationError()) {
            const error = { message: emailValidationError() } as ValidationError<User>
            errors.email = [error]
        }

        return errors
    }

    return (
        <CaptureForm
            onSubmit={onSubmit}
            errorFields={['email', 'marketingAccepted', 'policiesAccepted']}
            validateFields={validateFields}
            autocomplete="off"
        >
            <hgroup>
                <h4>Super!</h4>
                <h4>Let's build you a plan</h4>
            </hgroup>

            <fieldset>
                <TextBox name="email" placeholder="Email address" value={email()} onInput={handleEmailChange} onBlur={handleEmailBlur} autoFocus disabled={isDisabled}/>
            </fieldset>
            <fieldset class="toggle-options">
                <label>
                    <span>Include me in updates & offers by email</span>
                    <Toggle state={marketingAccepted()} setState={setMarketingAccepted}/>
                </label>
                <label>
                    <span>I agree to Peachy's <a target="_blank" href={POLICY_URL}>Privacy Policy</a> & <a target="_blank" href={LEGAL_URL}>Terms of Use</a></span>
                    <Toggle state={policiesAccepted()} setState={setPoliciesAccepted}/>
                </label>
            </fieldset>

        </CaptureForm>
    )
}
