import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../event-kit'
import {LifeEventData} from '../interfaces'
import {AccountType} from '@peachy/repo-domain'
import {LifeTurnover} from '@peachy/alterations-pure'

export class LifeAlteredNotification extends AnEvent implements LifeEventData {
    constructor(from: Properties<LifeAlteredNotification>) {
        super()
        Object.assign(this, from)
    }

    lifeTurnover?: LifeTurnover

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    cognitoId: string
    lifeId: string
}
