import {createStore} from 'solid-js/store'
import *  as PlanConfig from '@peachy/product-pure'
import {fetchServiceConfig} from '@peachy/core-domain-client'
import {useServicePatch} from '@peachy/client-kit'

type AppConfig = {
    plans: PlanConfig.Plans
}

const [store, setStore] = createStore<AppConfig>({ plans: {} } as AppConfig)

export async function configureApp() {
    const planConfig = await fetchServiceConfig('SmePlanConfig', useServicePatch())
    setStore({ plans: planConfig.config })
}

export function useAppConfig() {
    return store
}
