import {PaymentDetails} from '../PaymentDetails'
import {MrPolicy} from './MrPolicy'
import {Pence} from '../../some-types'
import {ActiveStatus} from './statuses'
import {MrPlan} from './MrPlan'
import Required from '../../validation/validators/Required'
import {Type} from 'class-transformer'
import {displayableId, getNextAnniversaryOf, getNextPaymentDateAfter, KeyMapped} from '@peachy/utility-kit-pure'
import {startOfTomorrow} from 'date-fns'

export const defaultTimezone = 'Europe/London'

export class MrSubscription {
    @Required()
    id: string

    version: number

    status: ActiveStatus

    @Required()
    startDate: number

    endDate?: number
    timezone: string = defaultTimezone

    billingAnchor?: number // integer day of month

    totalMonthlyPremium?: Pence

    stripeSubscriptionId?: string
    stripeProductId?: string

    plans: KeyMapped<MrPlan> = {}

    policies: KeyMapped<MrPolicy> = {}

    @Required()
    name: string

    @Required()
    contactName: string

    @Required()
    contactEmail: string

    @Required()
    @Type(() => PaymentDetails)
    paymentDetails?: PaymentDetails

    referenceNumber?(): string {
        return displayableId(this.id)
    }

    getNextPaymentDate?(): number {
        return getNextPaymentDateAfter(new Date(this.startDate), startOfTomorrow()).getTime()
    }

    getAnnualRenewalDate?(): Date {
        return getNextAnniversaryOf(new Date(this.startDate))
    }


}
