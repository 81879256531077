import {MrSubscription, toClass} from '@peachy/core-domain-pure'
import {readableDate} from '@peachy/utility-kit-pure'
import {isBefore} from 'date-fns'
import {Component, createMemo, Show} from 'solid-js'
import {getSubscription} from '../../../components/Home/HomeProvider'
import tableStyles from '../DashboardTable.module.css'
import {BillingInfoPanels} from '../panels/BillingInfoPanels/BillingInfoPanels'
import styles from './Billing.module.css'

export const Billing = () => {
    const subscription = createMemo(() => toClass(getSubscription(), MrSubscription))

    return (
        <div class={tableStyles.ListContainer}>
            <section>
                <h2>Billing</h2>
                <BillingInfoPanels />
                <table class={tableStyles.table}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>From</th>
                            <th>To</th>
                            { /* Download */}
                            <th class={styles.downloadColumn}></th>
                        </tr>
                    </thead>

                    <tbody>
                        <StatementRow fromDate={subscription().startDate} />
                    </tbody>
                </table>
            </section>
        </div>
    )
}

const StatementRow: Component<{ fromDate: number }> = (props) => {
    const now = new Date()

    return (
        <tr>
            <td>Statement</td>
            <Show when={isBefore(props.fromDate, now)} fallback={
                <td colSpan={3}>No payments have been made on this policy. A summary of payments will be available from <span class={styles.redHighlight}>{readableDate(props.fromDate)}</span></td>
            }>
                <td>{readableDate(props.fromDate)}</td>
                <td>{readableDate(now)}</td>
                <td class={styles.downloadColumn}><a class={tableStyles.link} target={'_blank'}>Download</a></td>
            </Show>
        </tr >
    )
}
