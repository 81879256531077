import {compareNumbers} from '@peachy/utility-kit-pure'
import {QuoteBenefitType} from '@peachy/quote-pure'
import {filterMap} from './MapUtils'

export function dotProduct(left: number[], right: number[]) {
    let dotProduct = 0
    for (let i = 0; i < left.length; i++) {
        dotProduct += left[i] * right[i]
    }
    return dotProduct
}

export function getSum(numbers: number[]) {
    let sum = 0
    for (let num of numbers) {
        sum += num
    }
    return sum
}

export function sumValues(map: Map<any, number>) {
    return getSum(Array.from(map.values()))
}

export function sumExcessBenefitsOnly(benefitValues: Map<QuoteBenefitType, number>, excessBenefits: QuoteBenefitType[]) {
    return sumValues(filterMap(benefitValues, (k, v) => excessBenefits.includes(k)))
}

export function roundSum(values: number[], resolution: number) {
    const desiredTotal = Math.round(getSum(values) / resolution) * resolution
    let i = 0
    const elements = values.map(v => ({index: i++, value: v}))

    // round all elements to their closest integer
    const roundedElements = elements.map(e => ({index: e.index, value: Math.round(e.value)}))

    // how far away from desired total are we?
    const roundedSum = getSum(roundedElements.map(e => e.value))
    const toAdjust = desiredTotal - roundedSum

    if (toAdjust != 0) {
        // find the amounts we rounded up or down by and sort by size
        const adjustmentsBySize = elements.map(e => ({index: e.index, value: roundedElements.find(r => r.index == e.index).value - e.value}))
            .sort((a, b) => a.value - b.value)
        let increment = 1
        if (toAdjust < 0) {
            adjustmentsBySize.reverse()
            increment = -1
        }
        // add 1 to the amounts that were rounded down the most, or subtract 1 from the amounts that were rounded up the most, until we are level
        for (let i = 0; i < Math.abs(toAdjust); i++) {
            roundedElements[adjustmentsBySize[i].index].value += increment
        }
    }

    roundedElements.sort((a, b) => compareNumbers(a.index, b.index))
    return roundedElements.map(e => e.value)
}
