import {lazy} from 'solid-js'

const iconMappings = {
    'ADVICE_COUNSELLING': lazy(() => import('./ADVICE_COUNSELLING')),
    'CONSULTATIONS_DIAGNOSTICS': lazy(() => import('./CONSULTATIONS_DIAGNOSTICS')),
    'DENTAL_OPTICAL': lazy(() => import('./DENTAL_OPTICAL')),
    'HOSPITAL_CARE': lazy(() => import('./HOSPITAL_CARE')),
    'MENTAL_HEALTH': lazy(() => import('./MENTAL_HEALTH')),
    'THERAPIES': lazy(() => import('./THERAPIES')),
    'VIRTUAL_GP': lazy(() => import('./VIRTUAL_GP')),
}

export function getBenefitIcon (benefitId: string) {
   return iconMappings[benefitId]
}
