import {Coupon, Promo} from '@peachy/payments-pure/src/domain'
import {PeachyApiService, RestApi} from './PeachyApiService'


export class CouponService extends PeachyApiService {
    constructor(api: RestApi) {
        super(api, 'PaymentsApi')
    }

    async getCoupon(code: string): Promise<Coupon> {
        try {
            return await this.invoke<Coupon, void>(`/coupon/${code}`)
        } catch (e) {
            return null
        }
    }

    async getPromo(code: string): Promise<Promo> {
        try {
            return await this.invoke<Promo, void>(`/promo/${code}`)
        } catch (e) {
            return null
        }
    }
}
