import {Component, createSignal, Show} from 'solid-js'
import {createToggleSignal} from '@peachy/client-kit'
import {currency, currencyFromPence, penceToPounds} from '@peachy/utility-kit-pure'
import {PopupListSelector} from '../../PopupListSelector/PopupListSelector'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {BenefitValueProps} from '../PlanConfiguration/PlanConfiguration'
import styles from './AnnualLimit.module.css'

export const AnnualLimit: Component<BenefitValueProps> = (props) => {
    const store = useStore()
    const storedLimit = store.getBenefitLimit(props.planId, props.benefitConfig.id)

    const [currentValue, setCurrentValue] = createSignal(storedLimit ?? props.benefitConfig.defaultLimit as number)
    const [showLimitSelector, toggleLimitSelector] = createToggleSignal(false)

    const displayList = props.benefitConfig.values.map(v => currency(penceToPounds(v)))

    let limitRef: HTMLParagraphElement

    const onSelect = (_: string, index) => {
        setCurrentValue(Number(props.benefitConfig.values[index]))
        toggleLimitSelector()
        store.updateBenefitLimit(props.planId, props.benefitConfig.id, currentValue())
    }

    const currentValueInPounds = () => currencyFromPence(currentValue())

    return <>
        <label>Annual limit</label>
        <Show when={props.isEditable && props.benefitConfig.isEditable} fallback={<>{currentValueInPounds()}</>}>
            <p class={styles.limit} ref={limitRef} onclick={toggleLimitSelector}>{currentValueInPounds()} <i class={'fa-solid fa-caret-down'}/></p>
            <PopupListSelector
                list={displayList}
                onSelect={onSelect}
                onDismiss={toggleLimitSelector}
                selection={currentValueInPounds()}
                enabled={showLimitSelector()}
                locatorElement={limitRef}
            />
        </Show>
    </>
}
