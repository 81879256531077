import {last} from './array-kit'

type DisburseResponse = {
    balanceBefore: number
    disbursed: number
    shortfall: number
    balanceAfter: number
}

export class Fund {
    constructor(private balance: number) { }

    public disburse(amount: number): DisburseResponse {
        const disbursed = Math.min(amount, this.balance)
        const shortfall = amount - disbursed
        const preBalance = this.balance
        this.balance -= disbursed
        return { disbursed, shortfall, balanceBefore: preBalance, balanceAfter: this.balance }
    }
}


export class Funds {

    private constructor(readonly funds: Fund[]) {
    }

    static wrapExisting(funds: Fund[]): Funds {
        return new Funds(funds)
    }

    static fromBalances(balances: number[]): Funds {
        return new Funds(balances.map(it => new Fund(it)))
    }

    public disburseChainingTheShortfall(amount: number): DisburseResponse[] {
        const disbursments = this.funds.slice(0, 1).map(fund => fund.disburse(amount))

        this.funds.slice(1).forEach(fund => disbursments.push(
            fund.disburse(last(disbursments).shortfall)
        ))

        return disbursments
    }

    public disburseAmountFromEvery(amount: number): DisburseResponse[] {
        return this.funds.map(fund => fund.disburse(amount))
    }
}
