import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../event-kit'
import {CancellationEventData, LifeEventData} from '../interfaces'
import {CancellationType} from '../CancellationType'
import {AccountType} from '@peachy/repo-domain'

export class LifeCancelledNotification extends AnEvent implements LifeEventData, CancellationEventData {
    constructor(from: Properties<LifeCancelledNotification>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    lifeId: string
    cognitoId: string
    cancellationReason: string
    cancellationType: CancellationType
}
