import {Class} from 'type-fest'


type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'ALL'


type PathParamNames<PATH extends string> =
    PATH extends `${string}{${infer PARAM}}${infer REST}`
        ? [PARAM, ...PathParamNames<REST>]
        : []

type PathParamName<PATH extends string> = PathParamNames<PATH>[number]


export type PathParams<PATH extends string> = {
    [_k in PathParamName<PATH>]: string
}


export type ApiGatewayRouteDefinition = {
    method: HttpMethod
    path: string
    isPublic?: boolean
    requestType?: Class<unknown>,
    responseType?: Class<unknown>,
}


export type ApiGatewayRoutesDefinition = {
    [r: string]: ApiGatewayRouteDefinition
}


export type ApiGatewayDefinition<R extends ApiGatewayRoutesDefinition> = {
    name: string
    routes: R
}

export function defineApiGateway(named: string) {
    return {
        withRoutes<const RD extends ApiGatewayRoutesDefinition>(routes: RD) {
            return {
                name: named,
                routes
            }
        }
    }
}
