import {MrLife, MrPlan, MrPolicy} from '@peachy/core-domain-pure'
import {newUUID} from '@peachy/utility-kit-pure'
import {addYears} from 'date-fns'

const startDate = new Date().getTime()

export const plan: MrPlan = {
    id: newUUID(),
    name: 'DalphPlan',
    benefits: {
        CONSULTATIONS_DIAGNOSTICS: {
            id: 'CONSULTATIONS_DIAGNOSTICS',
            limit: 2_500_00
        },
        MENTAL_HEALTH_IN: {
            id: 'MENTAL_HEALTH_IN',
        },
        MENTAL_HEALTH_OUT: {
            id: 'MENTAL_HEALTH_OUT',
        },
    }
}

export const life: MrLife = {
    firstname: undefined,
    lastname: undefined,
    startDate: undefined,
    status: undefined,
    id: newUUID(),
    planId: plan.id,
    type: 'PRIMARY',
    dateOfBirth: addYears(startDate, -50).getTime(),
    gender: 'MALE',
    address: {
        display: '',
        building: ['19 Farnaby Road'],
        settlement: ['Shortlands', 'Bromley'],
        country: 'United Kingdom',
        county: 'London',
        postcode: 'BR1 4BL',
        location: {
            lat: 0,
            lng: 0,
        },
        region: 'London'
    },
    postcode: 'BR1 4BL',
    benefits: {}
}


export const policy: MrPolicy = {
    version: '0',
    status: undefined,
    id: newUUID(),
    lives: {
        [life.id]: life
    },
    startDate
}
