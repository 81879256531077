import {Navigate, Route, Router} from '@solidjs/router'
import {createSignal, lazy, Show} from 'solid-js'
import {Page} from './Page'
import {Home} from './components/Home/Home'
import {configureApp} from './config/configureApp'
import {configureAws} from './config/configureAws'
import {Billing} from './pages/dashboard/Billing/Billing'
import {Members} from './pages/dashboard/Members/Members'
import {YourPlan} from './pages/dashboard/YourPlan/YourPlan'
import {TouchScreen} from './pages/quote/TouchScreen'
import {AppContextProvider} from './providers/AppContextProvider'
import {useIamService} from './providers/AppServiceProvider'
import {hasTouchScreen} from './to-abstract/validate'
import './App.css'

export const ROOT_DOMAIN = 'peachy.health'
export const ROOT_DOMAIN_URL = `https://www.${ROOT_DOMAIN}/`
export const POLICY_URL = `${ROOT_DOMAIN_URL}privacy-policy`
export const LEGAL_URL = `${ROOT_DOMAIN_URL}legal`

export const FAQS_URL = `https://intercom-help.eu/peachy/en`

const QuoteAndBuyProvider = lazy(() => import('./pages/quote/QuoteAndBuyProvider'))
const Welcome = lazy(() => import('./pages/quote/Welcome'))
const IpidDocument = lazy(() => import('./components/Documents/Ipid/IpidDocument'))
const Disclosure = lazy(() => import('./components/Documents/Disclosure/Disclosure'))
const Login = lazy(() => import('./pages/Login'))
const Documents = lazy(() => import('./pages/dashboard/Documents/Documents'))

function PortalRoutes() {

    const iamService = useIamService()

    return (
        <Router>
            {/*open routes*/}
            <Show when={!iamService.isSignedIn()}>
                <Route path="/login" component={Login}/>
            </Show>
            {/* TODO Somehow put quote component within Home component */}
            <Route path="/quote/*" component={QuoteAndBuyProvider}/>
            <Route path="/welcome" component={Welcome}/>
            <Route path="/ipid" component={IpidDocument}/>
            <Route path="/disclosure" component={Disclosure} />

            {/* authenticated routes */}
            <Route path="/" component={Home}>
                <Route path="/documents" component={Documents}/>
                <Route path="/billing" component={Billing}/>
                <Route path="/members" component={Members}/>
                <Route path="/plans" component={YourPlan}/>
                <Route path="/plans/summary" component={YourPlan}/>
                <Route path="/" component={() => <Navigate href="/plans"/>}/>
            </Route>

             handle non-existent paths
            <Route path="*" component={() => <Navigate href="/"/>}/>
        </Router>
    )
}




function PortalApp() {
    const iamService = useIamService()
    const isTouchScreen = hasTouchScreen(window.navigator)

    return (
        <>
            <Show when={!isTouchScreen && !iamService.isBusy()}>
                <PortalRoutes/>
            </Show>
            <Show when={isTouchScreen}>
                <Page>
                    <TouchScreen/>
                </Page>
            </Show>
        </>
    )
}

export function App() {
    const [loaded, setLoaded] = createSignal(false)

    configureAws()
        .then(configureApp)
        .then(() => setLoaded(true))

    return (
        <Show when={loaded()}>
            <AppContextProvider>
                <PortalApp/>
            </AppContextProvider>
        </Show>
    )
}
