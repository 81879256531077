import tableStyles from '../ManageMembersTable.module.css'
import {LIFE_WARNING_ERROR_MESSAGES, validateLifeField} from '../../../../service/validation/ValidationService'
import {createToggleSignal, TextBox} from '@peachy/client-kit'
import {TableCell} from './TableCell'
import {Component, createMemo, Show} from 'solid-js'
import {LifeRowCellProps, validateFieldCss} from '../PolicyRow'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {classList} from '@peachy/utility-kit-pure'
import policyStyles from '../PolicyRow.module.css'

export const EmailCell: Component<LifeRowCellProps & { startDate?: number, disable?: boolean}> = (props) => {
    const store = useStore()
    const life = props.life

    const [isFocus, setIsFocus] = createToggleSignal(false)
    const isDuplicateEmail = createMemo(() => store.isEmailUnique(life.email))
    const textBoxCss = () => isFocus() ? '' : isDuplicateEmail() ? policyStyles.invalidField : validateFieldCss(life, 'email')
    const showWarning = () => !isFocus() && life?.email && (!validateLifeField(life, 'email') || isDuplicateEmail())
    const errorMessage = () => showWarning() ? isDuplicateEmail() ? LIFE_WARNING_ERROR_MESSAGES['EMAIL_UNIQUE'] : LIFE_WARNING_ERROR_MESSAGES['EMAIL_FORMAT'] : ''

    return <Show when={!props.disable} fallback={<td />}>
        <TableCell tdStyles={tableStyles.email} errorMessage={errorMessage()}>
            <TextBox value={life?.email}
                onChange={(email) => store.updateLife(props.policyIndex, life.id, { email: email })}
                class={classList(policyStyles.inputOverflow, textBoxCss())}
                onFocus={setIsFocus}
                onBlur={setIsFocus}
                readonly={props.readonly}
                placeholder={life.type === 'DEPENDANT' && props.readonly ? 'No email required' : ''}
            />
        </TableCell>
    </Show>
}
