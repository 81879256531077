import {Component} from 'solid-js'
import {PlanConfiguration} from './PlanConfiguration/PlanConfiguration'
import {PlanEmployeeAssignment} from './PlanAssignment/PlanEmployeeAssignment'
import styles from './PlanBuilder.module.css'

export const PlanBuilder: Component = () => {
    return (
        <div class={styles.planBuilder}>
            <PlanConfiguration/>
            <PlanEmployeeAssignment />
        </div>
    )
}
