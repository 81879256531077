import {MrLife} from './MrLife'
import {ActiveStatus} from './statuses'
import {Pence} from '../../some-types'
import {toClass} from '../../validation/validation-core'
import {KeyMapped} from '@peachy/utility-kit-pure'


export class MrPolicy {
    version: string
    id: string

    status: ActiveStatus
    startDate: number
    endDate?: number

    totalMonthlyPremium?: Pence

    lives: KeyMapped<MrLife> = {}


    getAllLives?(): MrLife[] {
        return Object.values(this.lives).map(l => toClass(l, MrLife))
    }


    getPrimaryLife?(): MrLife {
        const life = Object.values(this.lives).find(l => l.type === 'PRIMARY')
        return toClass(life, MrLife)
    }

    getSecondaryLife?(): MrLife {
        const life = Object.values(this.lives).find(l => l.type === 'SECONDARY')
        return toClass(life, MrLife)
    }

    getDependents?(): MrLife[] {
        const lives = Object.values(this.lives).filter(l => l.type === 'DEPENDANT')
        return lives.map(l => toClass(l, MrLife))
    }

    getAppCustomers?(): MrLife[] {
        const lives = Object.values(this.lives).filter(l => l.cognitoUserId)
        return lives.map(l => toClass(l, MrLife))
    }

    getNonPrimaryLives?(): MrLife[] {
        const lives = Object.values(this.lives).filter(l => l.type !== 'PRIMARY')
        return lives.map(l => toClass(l, MrLife))
    }

    getLifeByCognitoUserId?(cognitoUserId: string) {
        const life = Object.values(this.lives).find(it => it.cognitoUserId === cognitoUserId)
        return toClass(life, MrLife)
    }
}
