import {getDaysInMonth, isEqual, isToday as dateFnsIsToday, isValid} from 'date-fns'
import {CalenderMonthArray} from '@peachy/utility-kit-pure'

export type MonthYear = [number, number]
export type DayMonthYear = [number, number, number]

export type CalendarWeek = DayMonthYear[]

export type CalendarMonth = {
    monthYear: MonthYear
    weeks: CalendarWeek[]
}



export function getCalendarMonth(monthYear: MonthYear): CalendarMonth {

    const lastMonthYear = monthYearBefore(monthYear)
    const nextMonthYear = monthYearAfter(monthYear)

    const daysLastMonth = daysInMonth(monthYear)
    const daysThisMonth = daysInMonth(monthYear)

    const firstDayOfTheMonth = firstDayOfMonth(monthYear)

    const daysNeededFromLastMonth = (firstDayOfTheMonth - 1) || 7
    const daysNeededFromNextMonth = 42 - (daysNeededFromLastMonth + daysThisMonth)

    const firstDayInTable = (daysLastMonth + 1) - daysNeededFromLastMonth

    const calendarMonth: CalendarMonth = {
        monthYear,
        weeks: []
    }

    let currentWeek: CalendarWeek = []

    for (let d = firstDayInTable; d <= daysLastMonth; d++) {
        currentWeek = updateTable(d, lastMonthYear, currentWeek, calendarMonth)
    }
    for (let d = 1; d <= daysThisMonth; d++) {
        currentWeek = updateTable(d, monthYear, currentWeek, calendarMonth)
    }
    for (let d = 1; d <= daysNeededFromNextMonth; d++) {
        currentWeek = updateTable(d, nextMonthYear, currentWeek, calendarMonth)
    }
    return calendarMonth
}


function updateTable(day: number, monthYear: MonthYear, currentWeek: CalendarWeek, calendarMonth: CalendarMonth): CalendarWeek {
    currentWeek.push([day, ...monthYear])
    if (currentWeek.length % 7 === 0) {
        calendarMonth.weeks.push(currentWeek)
        return []
    }
    return currentWeek
}


export function monthYearBefore(monthYear: MonthYear): MonthYear {
    const [month, year] = monthYear
    const prevMonth = month > 1 ? month - 1 : 12
    const prevYear = month > 1 ? year : year - 1

    console.log('!', [month, year], [prevMonth, prevYear])

    return [prevMonth, prevYear]
}


export function monthYearAfter(monthYear: MonthYear): MonthYear {
    const [month, year] = monthYear
    const nextMonth = month < 12 ? month + 1 : 1
    const nextYear = month < 12 ? year : year + 1
    return [nextMonth, nextYear]
}

export function firstDayOfMonth(monthYear: MonthYear) {
    const [month, year] = monthYear
    return new Date(year, month - 1, 1).getDay() + 1
}


export function daysInMonth(monthYear: MonthYear) {
    const [month, year] = monthYear
    new Date(year, month - 1, 1)
    return getDaysInMonth(new Date(year, month - 1, 1))
}

export function toMonthYear(date: Date): MonthYear {
    return [date.getMonth() + 1, date.getFullYear()]
}

export function clampMonthYear(monthYear: MonthYear, minDate: Date, maxDate: Date): MonthYear {
    let [month, year] = monthYear

    const minMonth = minDate.getMonth()+1
    const maxMonth = maxDate.getMonth()+1
    const minYear = minDate.getFullYear()
    const maxYear = maxDate.getFullYear()

    const clampedYear = Math.min(Math.max(minYear, year), maxYear)
    let clampedMonth = clampedYear === minYear ?
        Math.max(minMonth, month): month

    if (clampedYear === maxYear) {
        clampedMonth = Math.min(maxMonth, clampedMonth)
    }

    return [clampedMonth, clampedYear]
}


export function isDateWithinBounds(date: Date, minDate: Date, maxDate: Date) {
    return minDate.getTime() <= date.getTime() && date.getTime() <= maxDate.getTime()
}

export function asDate([day, month, year]: DayMonthYear) {
    return new Date(year, month - 1, day)
}

export function isToday(date: Date) {
    return dateFnsIsToday(date)
}

export function monthOf(date: Date) {
    return date.getMonth() + 1
}

export function yearOf(date: Date) {
    return date.getFullYear()
}


export function listYearsBetween(minDate: Date, maxDate: Date) {
    let year = minDate.getFullYear()
    const endYear = maxDate.getFullYear()
    const years: string[] = []
    while (year <= endYear) {
        years.push((year++).toString())
    }
    return years
}

export function listMonthsForYear(year: number, minDate: Date, maxDate: Date) {
    let startMonth = 0
    let endMonth = 11
    if (year === minDate.getFullYear()) {
        startMonth = minDate.getMonth()
    }
    if (year === maxDate.getFullYear()) {
        endMonth = maxDate.getMonth()
    }

    const months: string[] = []
    for (let month = startMonth; month <= endMonth; month++) {
        months.push(CalenderMonthArray[month])
    }
    return months
}


export function monthDayYearEqual([day, month, year]: DayMonthYear, date: Date) {
    return date.getFullYear() === year
        && date.getMonth() + 1 === month
        && date.getDate() === day
}

export function areDatesEqual(a: Date, b: Date) {
    return isEqual(a, b)
}


export function noTime(date: Date) {
    return new Date(date.toDateString())
}

export function isValidDate(date: Date) {
    return isValid(date)
}
