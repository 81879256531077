import {NumericRange} from './utility-types'
import {sum} from './array-kit'
import {identity} from './any-kit'

export const trueMod = (n: number, m: number) => ((n % m) + m) % m

export const compareNumbers = (a: number, b: number) => Math.sign(a - b)

// TODO test and find duplicate in codebase
export const difference = (a: number, b: number) => Math.abs(a - b)

export const clampNumber = (num: number, a: number, b: number) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b))

export const lerp = (number: number, fromRange: NumericRange, toRange: NumericRange) => {
    const from = fromRange[1] - fromRange[0],
        to = toRange[1] - toRange[0]

    return ((number - fromRange[0]) / from) * to + toRange[0]
}

export const isOdd = (n: number) => n & 1
export const isEven = (n: number) => !isOdd(n)

// TODO Quote Engine utils
export const dotProduct = (l: number[], r: number[]) => sum(l.map((n, i) => n * r[i]), identity)

export function roundSum(values: number[], resolution: number) {
    type NumberElements = { index: number, value: number }
    const elements: NumberElements[] = []
    let i = 0
    values.forEach(t => elements.push({index: i++, value: t}))

    elements.sort(x => -x.value % 1)

    const actualTotal = sum(values, identity)
    const roundedTotal = Math.round(actualTotal / resolution) * resolution

    const scale = roundedTotal / actualTotal

    let scaledTotal = 0
    elements.forEach(e => {
        const scaledValue = Math.floor(e.value * scale)
        e.value = scaledValue
        scaledTotal += scaledValue
    })

    const toPlay = roundedTotal - scaledTotal

    for (let j = 0; j < toPlay; j++) {
        elements[j].value += 1
    }

    elements.sort((a, b) => compareNumbers(a.index, b.index))

    return elements.map(e => e.value)
}


export function randomInteger(limit: number) {
    return Math.floor(Math.random() * limit);
}
