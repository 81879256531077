import {
    AccountSubscriptionDto,
    defineApiGateway,
    MrSubscriptionQuoteRequest,
    MrSubscriptionQuoteResponse
} from '@peachy/core-domain-pure'

import {ApiGatewayClient} from '@peachy/core-domain-client'

import {ContentBucketResponse, ReissuePolicyDocRequest, ReissuePolicyDocResponse} from '../index'

export const SubscriptionApiGatewayDefinition = defineApiGateway('SubscriptionApiGateway').withRoutes({
    getSmeQuote: {
        method: 'POST',
        path: '/quote/sme',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },
    saveSmeQuote: {
        method: 'POST',
        path: '/quote/sme/save',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },

    loadSmeQuote: {
        method: 'GET',
        path: '/quote/sme/load',
        isPublic: false,
        responseType: MrSubscriptionQuoteResponse
    },

    loadActiveSmeSubscription: {
        method: 'GET',
        path: '/subscription/sme/load',
        isPublic: false,
        responseType: AccountSubscriptionDto
    },

    adminLoadActiveSubscription: {
        method: 'GET',
        path: '/subscription/load/{accountId}/{subscriptionId}',
        isPublic: false,
        responseType: AccountSubscriptionDto
    },
    activateSmeSubscription: {
        method: 'POST',
        path: '/quote/sme/activate',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse

    },
    alterSmeSubscription: {
        method: 'POST',
        path: '/subscription/sme/alter',
        isPublic: false,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse

    },
    getIndividualQuote: {
        method: 'POST',
        path: '/quote/individual',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },
    activateIndividualSubscription: {
        method: 'POST',
        path: '/quote/individual/activate',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse

    },
    alterIndividualSubscription: {
        method: 'POST',
        path: '/subscription/individual/alter',
        isPublic: false,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },

    reissueCompanyPolicyDoc: {
        method: 'POST',
        path: '/quote/sme/policy-doc',
        isPublic: true,
        requestType: ReissuePolicyDocRequest,
        responseType: ReissuePolicyDocResponse
    },

    resolveContentBucket: {
        method: 'POST',
        path: '/resolve-content-bucket',
        isPublic: false,
        responseType: ContentBucketResponse
    }

})

export type SubscriptionApiGateway = ApiGatewayClient<typeof SubscriptionApiGatewayDefinition>
