import {createToggleSignal} from '@peachy/client-kit'
import {classList} from '@peachy/utility-kit-pure'
import {batch, Component, createSignal} from 'solid-js'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {AddressLookup} from '../../../AddressLookup/AddressLookup'
import {useAddressChangeStore} from '../../store/AddressChangeStore'
import tableStyles from '../ManageMembersTable.module.css'
import {isValidOptionalField, LifeRowCellProps} from '../PolicyRow'
import policyRowStyles from '../PolicyRow.module.css'
import {TableCell} from './TableCell'
import {LIFE_WARNING_ERROR_MESSAGES} from '../../../../service/validation/ValidationService'

export const AddressCell: Component<LifeRowCellProps> = (props) => {
    const store = useStore()
    const addressChangeStore = useAddressChangeStore()

    const [isFocus, setIsFocus] = createToggleSignal(false)
    const [addressTextBoxValue, setAddressTextBoxValue] = createSignal(props.life.address?.display ?? '')

    const onFieldChange = (newValue) => {
        const displayText = props.life.address?.display
        addressChangeStore.updateAddressChange(props.policyId, (newValue || displayText) && displayText !== newValue)
        setAddressTextBoxValue(newValue)
    }

    const onSelectAddress = address => {
        batch(() => {
            store.updateLivesForPolicy(props.policyIndex, store.getPolicy(props.policyId), {
                address: address,
                postcode: address.postcode
            })

            addressChangeStore.updateAddressChange(props.policyId, false)
        })
    }

    const isValid = () => {
        if (isFocus()) {
            return true
        }

        if (useAddressChangeStore().hasAddressChanged(props.policyId)) {
            return false
        }

        if (addressTextBoxValue()) {
            return props.life.address?.display ? isValidOptionalField(props.life, 'address') : false
        } else {
            return useStore().isFullValidation() ? false : true
        }
    }

    const onBlur = () => {
        const displayText = props.life.address?.display

        if (!addressTextBoxValue()) {
            batch(() => {
                if (displayText) {
                    store.updateLivesForPolicy(props.policyIndex, store.getPolicy(props.policyId), {
                        address: undefined,
                        postcode: undefined
                    })
                }

                addressChangeStore.deleteAddressChange(props.policyId)
            })
        } else {
            // No change and re-display the address lookup display text
            addressChangeStore.updateAddressChange(props.policyId, false)
        }

        setIsFocus()
    }

    const showWarning = () => !isFocus() && addressTextBoxValue() && (addressChangeStore.hasAddressChanged(props.policyId) || !props.life.address?.display)
    const errorMessage = () => showWarning() ? LIFE_WARNING_ERROR_MESSAGES['ADDRESS'] : ''

    return <TableCell tdStyles={classList(tableStyles.address, policyRowStyles.AddressLookup, policyRowStyles.inputOverflow)} errorMessage={errorMessage()}>
        <AddressLookup initialSearchTerm={props.life.address?.display ?? ''}
                       onFieldChange={onFieldChange}
                       onSelectAddress={onSelectAddress}
                       onBlur={onBlur}
                       onFocus={setIsFocus}
                       isValid={isValid}
                       errorClass={policyRowStyles.invalidField}
                       resetStateOnClearInput
                       readonly={props.readonly}
        />
    </TableCell>
}
