import {AnEvent} from '../../event-kit'
import {LifeEventData} from '../interfaces'

export function lifeEventDataFrom<E extends AnEvent & LifeEventData>(event: E): LifeEventData {
    return {
        accountId: event.accountId,
        accountType: event.accountType,
        cognitoId: event.cognitoId,
        lifeId: event.lifeId,
        policyId: event.policyId,
        subscriptionId: event.subscriptionId
    }
}
