import {Class} from 'type-fest'
import {keys, last, Strinky} from '@peachy/utility-kit-pure'
import {
    ApiGatewayDefinition,
    ApiGatewayRouteDefinition,
    ApiGatewayRoutesDefinition,
    PathParams
} from '@peachy/core-domain-pure'
import {Signer} from '@aws-amplify/core'
import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'

import {fetchServiceConfig} from './fetchServiceConfig'
import {getZoneInfo} from '@peachy/zone-config-pure'


type RouteImpl<RD extends ApiGatewayRouteDefinition> =
    {} extends PathParams<RD['path']>
        ? RD['requestType'] extends Class<infer REQ>
            ? (request: REQ) => ResponseType<RD>
            : () => ResponseType<RD>
        : RD['requestType'] extends Class<infer REQ>
            ? (pathParams: PathParams<RD['path']>, request: REQ) => ResponseType<RD>
            : (pathParams: PathParams<RD['path']>) => ResponseType<RD>

type RoutesImpl<R extends ApiGatewayRoutesDefinition> = {
    [r in keyof R]: RouteImpl<R[r]>
}

type ResponseType<RD extends ApiGatewayRouteDefinition> =
    RD['responseType'] extends Class<infer RES>
        ? Promise<RES>
        : Promise<void>


function implementRoute<const RD extends ApiGatewayRouteDefinition>(
    apiName: string,
    routeDef: RD,
    remoteApi: typeof API,
    signer?: typeof Signer,
    auth?: typeof Auth,
): RouteImpl<RD> {

    type P = Parameters<RouteImpl<RD>>

    const impl = async (...args: P) => {
        const pathParams = routeDef.requestType ? args.slice(0, -1) : []

        const requestBody = routeDef.requestType ? last(args) : null

        const httpRequest = {
            body: requestBody,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            } as Strinky
        }

        if (!routeDef.isPublic) {
            const user = await Auth.currentAuthenticatedUser()
            httpRequest.headers.Authorization = user.signInUserSession.idToken.jwtToken
        }

        const path = insertParams(pathParams, routeDef.path)

        console.log(routeDef)
        switch (routeDef.method) {
            case 'POST': {
                return remoteApi.post(apiName, path, httpRequest)
            }
            case 'GET': {
                return remoteApi.get(apiName, path, httpRequest)
            }
            case 'DELETE': {
                return remoteApi.del(apiName, path, httpRequest)
            }
            case 'PUT': {
                return remoteApi.put(apiName, path, httpRequest)
            }
        }

        routeDef.path
    }

    return impl as RouteImpl<RD>
}


function insertParams(params: unknown[], path: string) {
    const paramSeparators = path.split(/\{[^}]*}/)
    if (params.length != paramSeparators.length - 1) {
        throw 'up'
    }
    return [
        paramSeparators[0],
        ...params.flatMap((p, i) => [
            p, paramSeparators[i+1]
        ])
    ].join('')
}



export type ApiConfig = {
    name: string
    endpoint: string
    authorizationType: string
}[]

const apiConfigs: Map<string, ApiConfig> = new Map()

export async function configureApiGateway(apiName: string) {
    const servicePatchUri = getZoneInfo().servicePatchUrl

    const servicePatch = await fetchServiceConfig(apiName, servicePatchUri)

    apiConfigs.set(apiName, {
        name: apiName,
        ...servicePatch
    })

    API.configure({
        endpoints: [...apiConfigs.values()]
    })
}



export async function makeApiGatewayClient<const R extends ApiGatewayRoutesDefinition>(
    apiDefinition: ApiGatewayDefinition<R>,
    remoteApi: typeof API,
    auth?: typeof Auth,
    signer?: typeof Signer,

): Promise<RoutesImpl<R>> {

    await configureApiGateway(apiDefinition.name)

    const client = {} as any

    const routeNames = keys(apiDefinition.routes)
    for (let route of routeNames) {
        client[route] = implementRoute(apiDefinition.name, apiDefinition.routes[route], remoteApi, signer, auth)
    }
    return client as RoutesImpl<R>
}

export type ApiGatewayClient<AD extends ApiGatewayDefinition<any>> = AD extends ApiGatewayDefinition<infer R>
    ? RoutesImpl<R>
    : never
