import {MrPlan} from '@peachy/core-domain-pure'
import {toTitleCase} from '@peachy/utility-kit-pure'
import {Component} from 'solid-js'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {useDocGenService} from '../../../providers/AppServiceProvider'
import DownloadPlanIcon from '../../../global/icons/Download'
import {PlanCard} from '../PlanConfiguration/PlanConfiguration'
import styles from '../PlanConfiguration/PlanConfiguration.module.css'
import {createMemberDocTicket} from './sample-plan-docs/createSamplePolicyData'


const filename = (plan: MrPlan) => {
    const planName = toTitleCase(plan.name).replaceAll(' ','')
    return `PeachyMemberPlan-${planName}-SampleLife.pdf`
}

export const DownloadSamplePlan: Component<{ plan: MrPlan}> = (props) => {
    const store = useStore(), docGenService = useDocGenService()

    const download = async () => {
        const ticket = createMemberDocTicket(props.plan, store.getSubscriptionStartDate())
        const link = await docGenService.getPdfLink(ticket, filename(props.plan))

        window.open(link, `_${props.plan.name}MemberSamplePlanPdf`)
    }

    return (
        <PlanCard planId={props.plan.id}>
            <div class={styles.planDownload}>
                <span class={styles.planDownloadIcon} onClick={download}><DownloadPlanIcon /></span>
            </div>
        </PlanCard>
    )
}
