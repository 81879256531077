import {Component, createSignal, Show} from 'solid-js'
import {currency, currencyFromPence, penceToPounds} from '@peachy/utility-kit-pure'
import {createToggleSignal} from '@peachy/client-kit'
import {PopupListSelector} from '../../PopupListSelector/PopupListSelector'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {ExcessValueProps} from '../PlanConfiguration/PlanConfiguration'
import styles from './ExcessLimit.module.css'

export const ExcessLimit: Component<ExcessValueProps> = (props) => {
    const store = useStore()
    const storeExcess = store.getExcess(props.planId)
    const displayList = props.excessConfig.values.map(v => currency(penceToPounds(v)))

    const [currentValue, setCurrentValue] = createSignal(storeExcess ?? props.excessConfig.defaultLimit as number)
    const [showLimitSelector, toggleLimitSelector] = createToggleSignal(false)

    let limitRef: HTMLParagraphElement

    const onSelect = (_: string, index) => {
        setCurrentValue(Number(props.excessConfig.values[index]))
        toggleLimitSelector()
        store.updatePlanExcess(props.planId, currentValue())
    }

    const currentValueInPounds = () => currencyFromPence(currentValue())

    return <>
        <Show when={props.isEditable} fallback={<>{currentValueInPounds()}</>}>
            <p class={styles.limit} ref={limitRef} onclick={toggleLimitSelector}>{currentValueInPounds()} <i class={'fa-solid fa-caret-down'}/></p>
            <PopupListSelector
                list={displayList}
                onSelect={onSelect}
                onDismiss={toggleLimitSelector}
                selection={currentValueInPounds()}
                enabled={showLimitSelector()}
                locatorElement={limitRef}
            />
        </Show>
    </>
}
