import {BenefitType} from '../PlanBenefit'

export type PlanChangeType = 'MEMBER_ADDED'
    | 'MEMBER_REMOVED'
    | 'BENEFIT_ADDED'
    | 'BENEFIT_REMOVED'
    | 'BENEFIT_INCREASED'
    | 'BENEFIT_DECREASED'

type Change = {
    type: PlanChangeType
    date: number
}

export type MemberChange = Change & {
    name: string
}

export type BenefitChange = Change & {
    benefitType: BenefitType
    newLimit?: number
}

export type PlanChange = MemberChange | BenefitChange
