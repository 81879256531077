import {Properties} from '@peachy/utility-kit-pure'
import {AnEvent} from '../../../event-kit'
import {LifeEventData} from '../../interfaces'
import {AccountType} from '../../../../domain/models/Account'
import {LifeTurnover} from '@peachy/alterations-pure'


export class IndividualPolicyAlterationDocSendRequest extends AnEvent implements LifeEventData {
    constructor(from: Properties<IndividualPolicyAlterationDocSendRequest>) {
        super()
        Object.assign(this, from)
    }

    lifeTurnover?: LifeTurnover

    cognitoId: string

    accountId: string
    accountType: AccountType
    lifeId: string
    policyId: string
    subscriptionId: string
}
