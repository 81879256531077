import {isArray, isNullish, isObject, isString} from './type-check-kit'

// find out import usages
import 'intl'
import 'intl/locale-data/jsonp/en-GB'

import {hashFor} from './hash-kit'

// Better name for the file?

export const NOOP = () => {
}
export const identity = <T>(t: T): T => t
export const any = (o: Object) => o && Object.keys(o).length





type MemoFunc<A extends [], R> = (...args: A) => R

export function memoize<const F extends MemoFunc<any, any>>(fn: F): F {
    const cache: any = {}
    return (async (...args: any[])=> {
        let argHash = hashFor(args)
        return (cache[argHash] = cache[argHash] || fn(...args))
    }) as F
}





export type AsyncProvider<T> = () => Promise<T>

export function memo<T>(create: AsyncProvider<T>): AsyncProvider<T> {
    let theOne: T
    return async () => theOne ?? (theOne = await create())
}





export function mapObject<T extends object = object>(object: object, mapper: (key: string, value: any) => any): T {
    const mapped: any = {}
    for (let [key, value] of Object.entries(object)) {
        mapped[key] = mapper(key, value)
    }
    return mapped
}


export function isEmpty(value: any[] | object | string) {
    if (isNullish(value)) return true

    if (isObject(value)) {
        return Object.keys(value).length === 0
    }
    else if (isArray(value) || isString(value)) {
        return isNullish(value) || value.length === 0
    }
}

export function removeUndefinedPropertiesFrom<T extends object = object>(thing: T): Partial<T> {
    return Object.entries(thing).reduce((collector, [key, value]) => {
        if (!isNullish(value)) {
            collector[key as keyof T] = value
        }
        return collector
    }, {} as Partial<T>)
}
