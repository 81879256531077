import {isFunction} from './type-check-kit'


export function promiseOrTimeout<T>(promise: Promise<T> | (() => Promise<T>), timeout?: number) {

    let actualPromise: Promise<T>

    if (isFunction(promise)) {
        actualPromise = promise()
    } else {
        actualPromise = promise
    }

    return new Promise<T>((resolve, reject) => {
        let timeoutId: any
        actualPromise
            .then(resolve)
            .catch(reject)
            .finally(() => clearTimeout(timeoutId))

        if (timeout) {
            timeoutId = setTimeout(() => {
                reject(`Promise timed out in ${timeout} millis`)
            }, timeout)
        }
    })
}




export interface ResolvablePromise<T> extends Promise<T> {
    resolve: (value: T, timeout?: number) => Promise<void>;
    reject: (error: any, timeout?: number) => Promise<void>;
}

export function resolvablePromise<T>(): ResolvablePromise<T> {
    let resolve: (value: any) => void
    let reject: (value: any) => void

    const promise = new Promise<T>((res, rej) => {
        resolve = res
        reject = rej
    }) as ResolvablePromise<T>

    promise.resolve = async (value: T, timeout: number = 0) => {
        return new Promise((res, rej) => {
            setTimeout(() => {
                resolve(value)
                setTimeout(() => res())
            }, timeout)
        })
    }

    promise.reject = (error, timeout: number = 0) => {
        return new Promise((res, rej) => {
            setTimeout(() => {
                reject(error)
                setTimeout(() => res())
            }, timeout)
        })
    }
    return promise
}

export function onNextTick(f: () => void) {
    new Promise<void>((res, rej) => {
        res()
    }).then(f)
}

export async function concurrentlyIgnoringErrors<T>(promises: Promise<T>[]): Promise<T[]> {
    const executionResult = await polyfillPromiseAllSettled(promises)
    // @ts-ignore
    return executionResult.filter(it => it.status != 'rejected').map(it => it.value)
}

// Promise.allSettled not available in version of react-native we can use with Expo...
async function polyfillPromiseAllSettled<T>(promises: Promise<T>[]): Promise<{ status: 'fulfilled' | 'rejected', reason?: any, value?: T }[]> {
    const mappedPromises = promises.map(promise =>
        promise.then(value => ({status: 'fulfilled', value}))
            .catch(reason => ({status: 'rejected', reason})),
    )
    // @ts-ignore
    return Promise.all(mappedPromises)
}

export async function repeat(times: number, task: (i: number) => void | Promise<void>) {
    for (let i = 0; i < times; i++) {
        await task(i)
    }
}

export async function mapAsync<T extends object = object>(array: any[], mapper: (value: any, index: number) => Promise<any>): Promise<T[]> {
    return Promise.all(array.map(mapper))
}


export async function mapObjectAsync<T extends object = object>(object: object, mapper: (key: string, value: any) => Promise<any>): Promise<T> {
    const mapped: any = {}
    for (let [key, value] of Object.entries(object)) {
        mapped[key] = await mapper(value, key)
    }
    return mapped
}
