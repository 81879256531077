import {
    Account,
    Life,
    LifeBenefit,
    MrAccount,
    MrLife,
    MrPlan,
    MrPolicy,
    MrSubscription,
    Plan,
    PlanBenefit,
    Policy,
    Subscription
} from '@peachy/core-domain-pure'
import {KeyMapped, mapById, values} from '@peachy/utility-kit-pure'

export function mapToLegacyAccount(account: MrAccount): Account {

    const legacyAccount: Account = {
        id: account.id,
        type: account.type,
        status: account.status,
        createdAt: account.startDate,
        name: account.name,
        company: account.company,
        user: account.user,
        contactName: account.contactName,
        contactEmail: account.contactEmail,
        stripeCustomerId: account.stripeCustomerId,
        subscriptions: mapById(values(account.subscriptions).map(mapToLegacySubscription))
    }
    return legacyAccount
}

export function mapToLegacySubscription(subscription: MrSubscription): Subscription {
    const legacySubscription: Subscription = {
        id: subscription.id,
        startDate: subscription.startDate,
        cancellationReason: 'ON-REQUEST',
        cancelledAt: subscription.endDate,
        contactEmail: subscription.contactEmail,
        contactName: subscription.contactName,
        name: subscription.name,
        paymentDetails: subscription.paymentDetails,
        planIds: values(subscription.plans).map(p => p.id),
        policyIds: values(subscription.policies).map(p => p.id),
        status: subscription.status,
        stripeProductId: subscription.stripeProductId,
        stripeSubscriptionId: subscription.stripeSubscriptionId,
        totalMonthlyPremium: subscription.totalMonthlyPremium,
    }
    return legacySubscription
}

export function mapToLegacyPlan(plan: MrPlan): Plan {
    return {
        id: plan.id,
        name: plan.name,
        excess: plan.excess,
        benefits: values(plan.benefits).map(benefit => {
            const legacyPlanBenefit: PlanBenefit = {
                limit: benefit.limit,
                type: benefit.id,
            }
            return legacyPlanBenefit
        }),
    }
}


export function mapToLegacyPlans(plans: KeyMapped<MrPlan>): KeyMapped<Plan> {
    return mapById(values(plans).map(mapToLegacyPlan))
}



export function mapToLegacyPolicy(policy: MrPolicy) {
    const legacyPolicy: Policy = {
        id: policy.id,
        cancellationReason: '',
        cancelledAt: policy.endDate,
        status: policy.status,
        startDate: policy.startDate,
        totalMonthlyPremium: policy.totalMonthlyPremium,
        lives: mapById(
            values(policy.lives).map(mapToLegacyLife)
        ),
    }
    return legacyPolicy

}



export function mapToLegacyLife(life: MrLife) {
    const legacyLife: Life = {
        id: life.id,
        cognitoUserId: life.cognitoUserId,
        intercomUserId: life.intercomUserId,
        endDate: life.endDate,
        startDate: life.startDate,
        planId: life.planId,
        firstname: life.firstname,
        lastname: life.lastname,
        email: life.email,
        dateOfBirth: life.dateOfBirth,
        gender: life.gender,

        phone: life.phone,
        address: life.address,
        postcode: life.postcode,

        idVerificationStatus: undefined,
        livenessCheckStatus: undefined,
        sanctionsCheckStatus: undefined,

        marketingAccepted: false,
        smsMarketingAccepted: false,

        totalMonthlyPremium: life.totalMonthlyPremium,
        type: life.type,
        benefits: values(life.benefits).map(benefit => {
            const legacyBenefit: LifeBenefit = {
                type: benefit.id,
                premium: benefit.premium,
                startDate: benefit.startDate,
                utilisation: 0
            }
            return legacyBenefit
        })
    }
    return legacyLife
}
