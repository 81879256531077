import {
    Address,
    defaultTimezone,
    LifeType,
    MrAccount,
    MrLife,
    MrPlan,
    MrPolicy,
    MrSubscription,
    MrSubscriptionQuoteResponse,
    PaymentDetails,
    User,
} from '@peachy/core-domain-pure'
import {Draft, newUUID} from '@peachy/utility-kit-pure'
import {startOfToday} from 'date-fns'
import * as planConfigService from '../../components/PlanBuilder/domain/plan-utils'
import {mapToExcess, mapToPlanBenefits} from '../../components/PlanBuilder/domain/plan-model-mapper'
import {ConfigurablePlans} from '../../components/PlanBuilder/domain/ConfigurablePlans'

export function createSubscriptionRequest(): Draft<MrSubscriptionQuoteResponse> {
    const startDate = startOfToday().getTime()

    return {
        account: createDraftAccount(),
        subscription: createDraftSubscription(startDate),
        plans: createPlans(),
        policies: [],
    }
}

function createDraftAccount(): Draft<MrAccount> {
    return {
        name: '',
        contactName: '',
        contactEmail: '',
        type: 'COMPANY',
        user: createDraftUser(),
    }
}

function createDraftUser(): Draft<User> {
    return {
        id: newUUID(),
        firstname: '',
        lastname: '',
    } as User
}

function createDraftSubscription(startDate: number): Draft<MrSubscription> {
    return {
        id: newUUID(),
        name: '',
        timezone: defaultTimezone,
        contactName: '',
        contactEmail: '',
        paymentDetails: {
            promoCode: '',
        } as PaymentDetails,
        startDate,
    }
}

export function createDraftLife(planId: string, type: LifeType = 'PRIMARY', address?: Draft<Address>): Draft<MrLife> {
    return {
        id: newUUID(),
        status: 'ACTIVE',
        type,
        firstname: '',
        lastname: '',
        dateOfBirth: null,
        gender: null,
        email: '',
        phone: '',
        address: address ?? null,
        postcode: address ? address.postcode : '',
        planId,
    }
}

export function createDraftPolicy(startDate: number, planId: string): Draft<MrPolicy> {
    const life = createDraftLife(planId)
    return {
        id: newUUID(),
        startDate,
        lives: {
            [life.id]: life,
        },
    }
}

/*
    This will populate the Plan objects with whatever benefits are 'active' by default (according to the current plan config json)

    Note:
        * We are only storing benefits if they are 'on' for a given plan
        * Some will not be included if they are 'off' by default e.g. Therapies, Dental/Optical Care
        * Some benefits map to multiple benefits which are stored separately in the benefits array (e.g. DENTAL_OPTICAL becomes two entries: 'DENTAL' and 'OPTICAL')
        * When benefits are changed, we will need to add and remove from the benefits list accordingly
*/
function createPlans(): MrPlan[] {
    return planConfigService.getAllPlans().map((planConfig: ConfigurablePlans.Plan) => ({
        id: newUUID(),
        configId: planConfig.id,
        name: planConfig.name,
        benefits: mapToPlanBenefits(planConfig.benefits, mapToExcess(planConfig.excess)),
        excess: mapToExcess(planConfig.excess)
    } as MrPlan))
}
