
// TODO there are a million representations of GeoLocation.  we need to unify this
export type GeoLocation = {
    latitude: number
    longitude: number
}

export function regionEncompassing(geoLocations: GeoLocation[]) {
    if (!geoLocations?.length) {
        // should fix - what about 1 location - pass min delta to avoid super zoom in
        const allLongitudes = geoLocations.map(it => it.longitude)
        const allLatitudes = geoLocations.map(it => it.latitude)
        // should fix - this is a naive implementation that probably won't work at the boundaries
        const northEast = geoLocation(Math.max(...allLongitudes)!, Math.max(...allLatitudes)!)
        const southWest = geoLocation(Math.min(...allLongitudes)!, Math.min(...allLatitudes)!)

        const longitudeDelta = (northEast.longitude - southWest.longitude)
        const latitudeDelta = (northEast.latitude - southWest.latitude)

        const center = geoLocation(
            (longitudeDelta / 2) + southWest.longitude,
            (latitudeDelta / 2) + southWest.latitude
        )

        const deltaPaddingFactor = 1.9

        return {
            ...center,
            longitudeDelta: longitudeDelta * deltaPaddingFactor,
            latitudeDelta: latitudeDelta * deltaPaddingFactor,
        }
    }
}

function geoLocation(lng: number, lat: number): GeoLocation {
    return {
        longitude: lng, latitude: lat
    }
}
