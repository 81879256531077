import styles from './PriceBreakdownModal.module.css'
import {FormattedPrice} from '../../FormattedPrice/FormattedPrice'
import {PriceBreakdownTable} from './PriceBreakdownTable'
import {createToggleSignal} from '@peachy/client-kit'
import {DismissibleModal} from '../../Modal/DismissibleModal'
import {getQuote} from '../QuoteController'
import {Show} from 'solid-js'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'

export function PriceBreakdownModal() {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)
    const store = useStore()

    const currentPrice = () => getQuote()?.totalMonthlyPremium

    return (
        <>
            <article class={styles.priceArticle} onClick={toggleIsOpen}>
                <Show when={store.hasValidSmeLifeCount() && currentPrice()}>
                    <FormattedPrice price={currentPrice()}/>
                    <span class={styles.priceExplained}>Price explained &rarr;</span>
                </Show>
            </article>

            <DismissibleModal isOpen={isOpen()} onDismiss={toggleIsOpen} class={styles.priceBreakdownModal}>
                <h3>Your price explained</h3>
                <p>
                    It's simple, we earn money from the premium that you pay. Here is the price breakdown for the plan you've selected:
                </p>
                <PriceBreakdownTable/>
            </DismissibleModal>
        </>
    )
}
