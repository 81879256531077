import {ukStyleDate} from '@peachy/utility-kit-pure'
import {Component} from 'solid-js'

export const InfoPanelPlanRenewalDate: Component<{ startDate: Date, renewalDate: Date }> = (props) => {
    const startDate = ukStyleDate(props.startDate)
    const renewalDate = ukStyleDate(props.renewalDate)

    return (
        <p>Your plan runs from <span>{startDate}</span> to <span>{renewalDate}</span></p>
    )
}
