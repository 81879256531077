import {createContext, createResource, ParentProps, Show, useContext} from 'solid-js'
import {createGeoLocationServiceClient, GeoLocationServiceClient} from './GeoLocationServiceClient'
import {Signer} from '@aws-amplify/core'

const GeoLocationServiceClientContext = createContext<GeoLocationServiceClient>()

type GeoLocationServiceControllerProps = ParentProps & {
    servicePatchUrl: string
}

export function GeoLocationServiceController(props: GeoLocationServiceControllerProps) {
    const [serviceClient] = createResource<GeoLocationServiceClient>( () => createGeoLocationServiceClient(props.servicePatchUrl, Signer))
    const hasServiceClient = () => !!serviceClient()
    return (
        <Show when={hasServiceClient()}>
            <GeoLocationServiceClientContext.Provider value={serviceClient()}>
                {props.children}
            </GeoLocationServiceClientContext.Provider>
        </Show>
    )
}


export function useGeoLocationServiceClient(): GeoLocationServiceClient {
    return useContext(GeoLocationServiceClientContext)
}
