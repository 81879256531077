import PeachySvg from './src/peachy.svg'
import SamplePlanSvg from './src/sample-plan-tab.svg'
import PartyPopper from './src/party-popper.svg'
import IpidDoc from './src/ipid.pdf'
import PddDoc from './src/pdd.pdf'
import SmeDisclosureDoc from './src/sme-disclosure.pdf'
import SmeIpidDoc from './src/sme-ipid.pdf'
import SmePddDoc from './src/sme-pdd.pdf'

export {PeachySvg}
export {SamplePlanSvg}
export {PartyPopper}

export { IpidDoc, PddDoc, SmeDisclosureDoc, SmeIpidDoc, SmePddDoc }

        import * as me from '@peachy/assets'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dalph/DalphSpace/peachy-home/repos/peachy-mono-repo/comp-kit/assets'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/assets'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    